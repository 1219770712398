label {
  @apply w-full text-sm font-normal text-secondary mb-3;
}
input,
textarea {
  @apply space-y-20;
}
.reactEasyCrop_Container {
  @apply backdrop-blur fixed;
}
.cropped_thumbnails {
  @apply rounded-lg inline-flex;
}
.reactEasyCrop_Controls {
  @apply absolute flex flex-col items-center justify-center mb-2 bottom-0 w-screen left-0;
}
.reactEasyCrop_Controls button {
  @apply shadow-xl;
}

/* styles.css */

/* Style the dropdown */
.react-select__control {
  @apply input input-bordered rounded-lg pr-0.5 pl-2 h-auto;
}

/* Style the dropdown when it's focused */
.react-select__control--is-focused {
  @apply bg-inherit focus:shadow-none;
}

/* Style the dropdown when it's hovered */
.react-select__control--is-hovered {
  @apply border-slate-600 bg-black dark:bg-black;
}

/* Style the placeholder */
.react-select__placeholder {
  @apply text-inherit;
}

/* Style the option */
.react-select__option {
  @apply py-2 px-4 text-gray-900;
}

/* Style the selected option */
.react-select__single-value {
  @apply text-inherit;
}

/* trigger date popup for type date */
.input-container input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: 0.75rem;
  position: relative;
  width: 100%;
}

input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
