.read-more ol {
    @apply list-decimal list-inside space-y-2;
}

.read-more ul {
    @apply list-disc list-inside space-y-2;
}

.read-more li {
    @apply my-2 text-justify mr-10;
}