  .dots {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    top: -1em;
    height: 0em;
  }
  
  .dot {
    @apply bg-secondary;
    border-radius: 50%;
    height: 0.5em;
    width: 0.5em;
    margin: 0 0.2rem;
    transition: all 0.5s ease;
    box-shadow: inset 2px 0px 2px -2px #333;
  }
  
  .dots .active {
    @apply bg-white;
    box-shadow: inset 2px 0px 2px -2px #333;
  }
  
  /* transform active dot right 2em with scroll */
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(2em);
    }
  }
  .carousel .activeDot {
    animation: scroll 0.5s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -1s);
  
  }