.ql-toolbar.ql-snow {
  border-radius: 8px 8px 0 0;
}
.ql-container.ql-snow {
  border-radius: 0 0 8px 8px;
}

.ql-container.ql-bubble {
  border-radius: 8px;
  @apply border dark:border-gray-800 border-gray-300 !outline-none focus-within:ring-2 dark:focus-within:ring-gray-800 focus-within:ring-gray-300 ring-offset-2 dark:ring-offset-black transition-all duration-200 ease-in-out;
}

.ql-container.ql-bubble p {
  padding-bottom: 0.8em;
}

.ql-editor.ql-blank::before {
  @apply dark:text-gray-300;
}

/* format placeholder */
.ql-editor::before,
.ql-editor.ql-blank::before {
  @apply text-base dark:text-gray-400 text-accent font-light not-italic;
}

/* make textarea bold dark bold */
.ql-editor strong {
  @apply dark:text-gray-300 font-semibold;
}
