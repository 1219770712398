.drawer-content {
  /* height of viewport */
  height: 100dvh;
  min-height: -webkit-fill-available;
  overscroll-behavior: contain;
}

html,
body {
  overflow: auto !important;
  height: 100% !important; /* ensures ios doesn't add black space on input tap */
  /* overflow: hidden !important; */
  touch-action: none;
}

.masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  width: auto;
}
.masonry-grid_column {
  background-clip: padding-box;
}

.identity {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: light;
}

/* daisyui component mods */

.btn {
  @apply font-medium;
}

.badge-accent {
  @apply font-normal bg-base-200 outline-none border-0;
}

.btn-primary,
.badge-primary {
  @apply text-white dark:text-black;
}

.btn-brand {
  @apply bg-brand outline-none border-0 hover:bg-brand hover:bg-opacity-60 dark:text-black;
}

.chat-bubble {
  @apply bg-base-200 text-base-content;
}

/* ---- */

#loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

h1 {
  @apply text-3xl font-medium text-balance;
}

h2 {
  @apply text-lg font-normal text-balance;
}

p {
  @apply text-base font-light space-y-2 my-2 text-pretty;
}

strong {
  @apply font-semibold;
}

.tab-active {
  @apply underline underline-offset-8 decoration-2;
}

.drawer label {
  @apply text-base-content;
}

/* Set heroicon's stroke width */
.heroicon-sw-3 path {
  stroke-width: 3 !important;
}
.heroicon-sw-2\.5 path {
  stroke-width: 2.5 !important;
}
.heroicon-sw-2 path {
  stroke-width: 2 !important;
}
.heroicon-sw-1\.75 path {
  stroke-width: 1.75 !important;
}
.heroicon-sw-1\.5 path {
  stroke-width: 1.5 !important;
}
.heroicon-sw-1\.2 path {
  stroke-width: 1.2 !important;
}

.steps .step-primary:before,
.steps .step-primary:after {
  @apply text-white bg-black dark:bg-white dark:text-black !important;
}

.steps .step-brand:before,
.steps .step-brand:after {
  @apply text-white bg-brand dark:bg-brand dark:text-black !important;
}

.steps .step-brand {
  @apply text-accent !important;
}

.steps > .step-primary:not(.step-primary ~ .step-primary) {
  @apply font-bold text-black dark:text-white !important;
}

.steps > .step-primary:not(.step-primary ~ .step-primary):before,
.steps > .step-primary:not(.step-primary ~ .step-primary):after {
  @apply bg-brand !important;
}

.steps .step-primary:before,
.steps .step-primary:after {
  @apply text-white  bg-gray-300 dark:bg-white dark:text-black !important;
}

.tooltip-right:before {
  @apply mt-7 -ml-6 !important;
}

.tooltip-right:after {
  @apply mt-2 -ml-2 transform rotate-90 !important;
}

/* turn 180 */
.rotate-180 {
  transform: rotate(180deg);
}
